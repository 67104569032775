@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.spaceList {
    position: absolute;
    width: 280px;
    z-index: 401;
    top: 50px;
    right: 50%;
}

.spaceForm {
    position: absolute;
    z-index: 999;
    width: 95%;
    max-width: 500px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.20);
    background: $white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.spaceFormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0 10px;
}
